import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import MySwiper from "../components/swiper/swiper"
import WebriqForm from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import aboutWeed from "../images/about-us.jpg"

import vpIcon1 from "../images/vp-icon1.svg"
import vpIcon2 from "../images/vp-icon2.svg"
import vpIcon3 from "../images/vp-icon3.svg"

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<section className="container-fluid hero">
					<div className="row">
						<div className="col">
							<div className="text-center">
								<h1
									data-aos="fade-down"
									data-aos-duration="600"
									data-aos-delay="1000"
								>
									CANNABIS <span>SOUTH</span>
								</h1>
								<h4
									data-aos="fade"
									data-aos-duration="600"
									data-aos-delay="600"
								>
									BUILDING THE WAY OF THE FUTURE THROUGH CANNABIS AND HEMP
								</h4>
								<a className="btn-global main-cta" href="/">
									Learn More
								</a>
							</div>
						</div>
					</div>
				</section>
				<section id="about-cannabis" className="our-work">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<div
									data-aos="fade-left"
									data-aos-duration="600"
									data-aos-delay="300"
								>
									<div className="">
										<img
											alt="website"
											className="img-fluid img-border"
											loading="lazy"
											src={aboutWeed}
										/>
									</div>
								</div>
								<div className="about-overlay" />
							</div>
							<div className="col-md-6">
								<div
									data-aos="fade-left"
									data-aos-duration="600"
									data-aos-delay="300"
								>
									<h1 data-aos="fade-down" data-aos-duration="600">
										ABOUT CANNABIS SOUTH
									</h1>
									<hr className="mb-4" data-aos="fade" />
									<p
										className="text-black-50 subheading"
										data-aos="fade-up"
										data-aos-duration="600"
									>
										Cannabis South is a global leader in cannabis and hemp
										research, cultivation, processing and distribution. We
										apsire to lead, legitmize and define the future of our
										industry by building the world's most trusted duel cannabis
										and hemp company.
									</p>
									<p
										className="text-black-50 subheading mb-5"
										data-aos="fade-up"
										data-aos-duration="600"
									>
										A proud pioneer, we are the first minority hemp producer to
										supply fiber and extract products to tens of thousands of
										patients in South.
									</p>
									<a className="btn-global" href="/">
										Learn More
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="our-products">
					<div className="container">
						<div className="row">
							<div className="col-9 mx-auto mb-5 text-center">
								<h5 id="gray">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur.
								</h5>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row products">
							<div className="products-overlay"></div>
							<div className="prod-overlay text-center">
								<h1 className="text-white">Products</h1>
							</div>
							<div className="products1 col-lg-6 col-md-6 col-xs-12">
								<div className="prod-content"></div>
								<div className="prod-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">CDB LOTIONS SALVE</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
							<div className="products2 col-lg-3 col-md-3 col-xs-12">
								<div className="prod-content"></div>
								<div className="prod-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">TINCTURES</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
							<div className="products3 col-lg-3 col-md-3 col-xs-12">
								<div className="prod-content"></div>
								<div className="prod-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">GEL CAPS</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="row services">
							<div className="services-overlay"></div>
							<div className="service-overlay text-center">
								<h1 className="text-white">Services</h1>
							</div>
							<div className="services1 col-lg-3 col-md-3 col-xs-12">
								<div className="service-content"></div>
								<div className="service-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">RESEARCH</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
							<div className="services2 col-lg-3 col-md-3 col-xs-12">
								<div className="service-content"></div>
								<div className="service-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">CULTIVATION</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
							<div className="services3 col-lg-3 col-md-3 col-xs-12">
								<div className="service-content"></div>
								<div className="service-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">PROCESSING</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
							<div className="services4 col-lg-3 col-md-3 col-xs-12">
								<div className="service-content"></div>
								<div className="service-content-overlay">
									<div className="text-center mx-auto">
										<h3 className="mt-0 mb-0">DISTRIBUTION</h3>
										<Link to="/">
											<h5 className="mt-0 mb-0">LEARN MORE &gt;</h5>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="our-services">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-xs-12">
								<div className="row vp-content">
									<div className="col-lg-3 col-md-2 col-xs-2">
										<img
											alt="website"
											className="img-fluid img-border vp-icon"
											loading="lazy"
											src={vpIcon1}
										/>
									</div>
									<div className="col-lg-9 col-md-10 col-xs-10">
										<h3 className="mt-0 mb-0">LOREM IPSUM</h3>
										<hr className="mb-4" data-aos="fade" />
										<p id="gray">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-xs-12">
								<div className="row vp-content">
									<div className="col-lg-3 col-md-2 col-xs-2">
										<img
											alt="website"
											className="img-fluid img-border vp-icon"
											loading="lazy"
											src={vpIcon2}
										/>
									</div>
									<div className="col-lg-9 col-md-10 col-xs-10">
										<h3 className="mt-0 mb-0">TEMPOR INCUDIDUNT</h3>
										<hr className="mb-4" data-aos="fade" />
										<p id="gray">
											Duis aute irure dolor in reprehenderit in voluptate velit
											esse cillum dolore eu fugiat nulla pariatur.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-xs-12">
								<div className="row vp-content">
									<div className="col-lg-3 col-md-2 col-xs-2">
										<img
											alt="website"
											className="img-fluid img-border vp-icon"
											loading="lazy"
											src={vpIcon3}
										/>
									</div>
									<div className="col-lg-9 col-md-10 col-xs-10">
										<h3 className="mt-0 mb-0">ADISPISCING ELIT</h3>
										<hr className="mb-4" data-aos="fade" />
										<p id="gray">
											Excepteur sint occaecat cupidatat non proident, sunt in
											culpa qui officia deserunt mollit anim id est laborum.
										</p>
									</div>
								</div>
							</div>
							<a className="btn-global mx-auto mt-5" href="/">
								Learn More
							</a>
						</div>
					</div>
				</section>
				<section id="contact-us">
					<div className="container">
						<div className="row">
							<div className="col-md-8 offset-md-2">
								<div className="newsletter-wrapper">
									<div className="newsletter-subwrapper">
										<div className="text-center">
											<h1
												className="contact-header"
												data-aos="fade-down"
												data-aos-duration="600"
											>
												CONTACT US
											</h1>
											<p>
												Nunc luctus in metus eget fringilla. Aliquam sed justo
												ligula. Vestibulum nibh erat, pellentesque ut laoreet
												vitae.
											</p>
										</div>
										<WebriqForm
											form_name="contact"
											form_id="5db168c4cc9b735a4d8c3f30"
											form_classname="contact_form"
										>
											<div className="form-row my-3">
												<div className="form-group form-active">
													<input
														className="form-control"
														type="text"
														name="Full Name"
														id="fullName"
													/>
													<label htmlFor="fullName">Name</label>
												</div>
											</div>
											<div className="form-row my-3">
												<div className="form-group form-active">
													<input
														className="form-control"
														type="text"
														name="Email"
														id="emailAddress"
													/>
													<label htmlFor="emailAddress">Email Address</label>
												</div>
											</div>
											<div className="form-row my-3">
												<div className="form-group form-active">
													<input
														className="form-control form-textarea"
														name="Message"
														id="message"
													/>
													<label htmlFor="message">Message</label>
												</div>
											</div>
											<div className="mt-5 form-row">
												<div className="centered-captcha mb-4 mx-auto">
													<div className="webriq-recaptcha" />
												</div>
											</div>
											<button type="submit" className="btn-global w-100">
												SUBSCRIBE
											</button>
										</WebriqForm>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
